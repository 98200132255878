@use 'reset';
@use 'atoms';
@use 'sass:math';

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500;700&display=swap');

@keyframes animate-in {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@mixin animate-in($delay: 0, $duration: 400ms) {
  opacity: 0;

  animation-name: animate-in;
  animation-duration: $duration;
  animation-delay: $delay;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@mixin size($width, $height, $scale: 1) {
  height: $height * $scale;
  width: $width * $scale;
}

@mixin font-size($font-size, $line-height) {
  font-size: $font-size;
  line-height: $line-height;
}

* {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

html {
  background: #F7F9FC;
  font-family: atoms.$font-family;
}

strong {
  font-weight: bold;
}

.site {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  min-height: 100vh;
  padding: atoms.$space_base;
  width: 100vw;
}

@media screen and (min-width: 768px) {
  .site {
    grid-template-columns: 1fr 1fr;
  }
}

// content

.content {
  align-self: center;
  justify-self: center;
  margin-bottom: atoms.$space_large;
  max-width: 340px;
  width: 100%;
}

@media screen and (min-width: 768px) {
  .content {
    justify-self: end;
    margin-bottom: 0;
    max-width: 320px;
  }
}

@media screen and (min-width: 1024px) {
  .content {
    max-width: 440px;
  }
}

.content__header {
  margin-bottom: atoms.$space_base;
}

.content__header__name {
  @include animate-in(0ms);
  @include font-size(24px, 1.4);

  font-weight: bold;
}

.content__header__pronoun {
  @include animate-in(100ms);
  @include font-size(18px, 1.4);

  font-style: italic;
}

.content__paragraph {
  @include animate-in(200ms);
  @include font-size(20px, 1.4);

  margin-bottom: atoms.$space_base;
}

.content__sub-paragraph {
  @include animate-in(300ms);
  @include font-size(18px, 1.4);

  margin-bottom: atoms.$space_base;
}

@media screen and (min-width: 768px) {
  .content__header__name {
    @include font-size(28px, 1.2);
  }

  .content__header__pronoun {
    @include font-size(20px, 1.4);
  }

  .content__paragraph {
    @include font-size(24px, 1.4);
  }

  .content__sub-paragraph {
    @include font-size(20px, 1.4);
  }
}

@media screen and (min-width: 1024px) {
  .content__header__name {
    font-size: 32px;
    line-height: 1.2;
  }

  .content__header__pronoun {
    font-size: 24px;
    line-height: 1.4;
  }

  .content__paragraph {
    font-size: 28px;
    line-height: 1.4;
  }

  .content__sub-paragraph {
    font-size: 24px;
    line-height: 1.4;
  }
}

.nav__list {
  @include animate-in(400ms);
  display: grid;
  gap: atoms.$space_small;
  grid-template-columns: 1fr;
  margin-bottom: atoms.$space_base;
}


$nav-icon_height: 32px;

.nav__label {
  font-size: 16px;
  font-weight: bold;
  line-height: $nav-icon_height;
  margin-right: atoms.$space_small;
}

@media screen and (min-width: 768px) {
  .nav__label {
    font-size: 20px;
    line-height: 1;
  }
}

a.nav__link {
  align-items: center;
  border-radius: 8px;
  border: 1px solid black;
  color: black;
  display: inline-flex;
  padding: atoms.$space_small atoms.$space_base;
  text-decoration: none;

  .nav__icon--arrow-right {
    transition-duration: 500ms;
  }

  &:hover {
    background-color: hsl(240, 90%, 96%);
    transition-duration: 200ms;

    .nav__icon--arrow-right {
      transform: translateY(-8px) translateX(8px);

      transition-duration: 400ms;
    }
  }

  &:active {
    background-color: hsl(240, 90%, 86%);
    transform: scale(0.99);
  }
}


.nav__icon {
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: $nav-icon_height;
  width: 24px;
}

.nav__icon--github {
  background-image: url('../images/icon/github.svg');
  margin-right: atoms.$space_small;
}

.nav__icon--dribbble {
  background-image: url('../images/icon/dribbble.svg');
  margin-right: atoms.$space_small;
}

.nav__icon--behance {
  background-image: url('../images/icon/behance.svg');
  margin-right: atoms.$space_small;
}

.nav__icon--arrow-right {
  background-image: url('../images/icon/arrow-right.svg');
  height: 24px;
  width: 10px;
}

$email-icon_height: 32px;

a.email__link {
  @include animate-in(500ms);
  align-items: center;
  border-radius: 8px;
  display: inline-flex;
  text-decoration: none;

  &:hover {
    text-decoration: underline;

    .email__icon--arrow-up {
      transform: translateY(-2px);
    }
  }

  &:active {
    transform: scale(0.99);
  }
}

.email__icon {
  background-image: url('../images/icon/email.svg');
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  height: $email-icon_height;
  margin-right: atoms.$space_extra-extra-small;
  width: 20px;
}

.email__label {
  color: black;
  font-size: 18px;
  line-height: $email-icon_height;
}

.email__icon--arrow-up {
  background-image: url('../images/icon/arrow-up.svg');
  height: 24px;
  width: 12px;
}

// illustration

.illustration {
  @include animate-in(100ms, 400ms);
  align-self: start;
  justify-self: center;
}

@media screen and (min-width: 768px) {
  .illustration {
    align-self: center;
    justify-self: start;
  }
}

.illustration__image--desktop {
  display: none;
  height: 512px;
  margin-left: atoms.$space_base;
  transition-duration: 200ms;
  width: 480px;
}

@media screen and (min-width: 768px) {
  .illustration__image--desktop {
    @include size(480px, 512px, 0.6);

    display: block;
    margin-left: atoms.$space_base;
  }
}

@media screen and (min-width: 860px) {
  .illustration__image--desktop {
    @include size(480px, 512px, 0.8);

    margin-left: atoms.$space_base;
  }
}

@media screen and (min-width: 1140px) {
  .illustration__image--desktop {
    @include size(480px, 512px, 1);
    margin-left: atoms.$space_large;
    transform: scale(1);
  }
}

.illustration__image--mobile {
  height: 280px;
  width: 300px;
}

@media screen and (min-width: 768px) {
  .illustration__image--mobile {
    display: none;
  }
}