$font-size: (
  'heading': (
    'base': (24px, 1.5),
  ),
);

$font-family: 'Inter', sans-serif;

$space_extra-extra-small: 4px;
$space_extra-small: 8px;
$space_small: 12px;
$space_base: 20px;
$space_medium: 32px;
$space_large: 40px;
$space_extra-large: 80px;

